import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { useFlags } from 'flagsmith/react';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import { useRouter } from 'next/router';

import { http } from '@hl-portals/libs/http';

import {
  BrandThemeProvider,
  Form,
  MultiStep,
  PageLoading,
  Upload,
  useMultiStep,
  useUpload,
} from '@hl-portals/ui';

import { handleErrors } from '@hl-portals/helpers';

import {
  LenderBrandResponse,
  useAutomatedBbysEligibilityCheck,
  useCalculateLenderEquity,
  useCreateSnapshot,
  useFetchSnapshot,
  useModal,
  usePropertyUUID,
  usePublicEvent,
  useRunOnce,
  useStateEligibilityCheck,
  useUpdateSnapshot,
} from '@hl-portals/hooks';

import {
  AddAgentInfo,
  AddAssistantInfo,
  AddClientInformation,
  AgeRestrictedCommunity,
  AgentInformation,
  ClientFinance,
  ClientInformation,
  CreditScore,
  DepartingResidence,
  EquityCalculation,
  EquityCalculationLoading,
  EquityCalculationModal,
  FinancialInformation,
  FinancialType,
  FlowErrorView,
  Header,
  HomeValue,
  Layout,
  LeadInformation,
  LiensBalances,
  LiensSelection,
  LoanOfficerInformation,
  NewHomeClosingDate,
  NewHomePurchaseContract,
  NewHomePurchaseFunds,
  NewHomePurchaseTimeline,
  NewHomeTargetEquityAmount,
  NewHomeUnderContract,
  PrimaryResidence,
  ProgramEligibility,
  ReviewAndSubmit,
  Sidebar,
  Success,
  Trust,
} from '../../components';
import { LeadSubmissionContext } from '../../contexts/lead-submission';
import {
  getCalculateEquityPayload,
  getCalculationIssuesLabels,
  getCurrentFlowFromFlow,
  getEligibilityFormData,
  getFlowFromPartner,
  getPropertyEligibilityCheckPayload,
  getStepsToSkip,
  getSubmitPayload,
  getSubmitResponse,
  getSubmitUrl,
  getUpdateSnapshotPayload,
} from '../../helpers';
import { LOCAL_STORAGE_KEY, STEPS, Step } from '../../metadata/constants';
import { FormData, FormKey, schema } from '../../metadata/forms';
import {
  CreateSnapshotPayload,
  FlowError,
  RouterQuery,
  SnapshotData,
  SubmissionResponse,
  UpdateSnapshotPayload,
} from '../../metadata/types';

type LeadSubmissionProps = {
  brand?: LenderBrandResponse;
};

export const LeadSubmission = ({ brand }: LeadSubmissionProps) => {
  const {
    'equity-lead-submission-flow-phase-2': { enabled: isLeadSubmissionPhase2 },
  } = useFlags(['equity-lead-submission-flow-phase-2']);

  const [isPageLoading, setIsPageLoading] = useState(true);

  const router = useRouter();
  const query = router.query as RouterQuery;

  const { uuid, partnerSlug, flow } = query;
  const flowValue = flow || getFlowFromPartner(partnerSlug);
  const currentFlowValue = getCurrentFlowFromFlow(flowValue);

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      flow: flowValue,
      partner_slug: partnerSlug,
      current_flow: currentFlowValue,
    },
  });

  const formData = form.watch();
  const multiStep = useMultiStep({ skip: getStepsToSkip(formData) });
  const { openModal, closeModal } = useModal();

  const upload = useUpload({
    accept: ['pdf', 'doc', 'docx', 'xml'],
    source: 'equity-app',
    maxFileSize: 10000, // KB
    unique: true,
  });

  // ========================================================================
  // Events

  const { recordPublicEvent } = usePublicEvent(
    'bbys_public_lead_submission_lender'
  );

  const { recordPublicEvent: recordErrorEvent } = usePublicEvent(
    'bbys_public_lead_submission_lender_error'
  );

  const getEventPayload = (
    _payload: Partial<FormData & { error?: AxiosError }> = {}
  ) => ({
    last_seen_page: multiStep.currentStep,
    snapshot_uuid: uuid,
    slug: partnerSlug,
    lead_id: formData?.submit_response?.lead_id,
    ..._payload,
  });

  // ========================================================================
  // Fetch Snapshot

  const { data: snapshot, isLoading: isFetchSnapshotLoading } =
    useFetchSnapshot<SnapshotData>(uuid, {
      onSuccess: (_snapshot) => {
        const { payload } = _snapshot.attributes;

        form.setValue('snapshot_uuid', uuid);
        form.setValue('snapshot_id', _snapshot.id);

        for (const key in payload) {
          const k = key as FormKey;
          const v = payload[key];

          if (k === 'current_flow') {
            const cf = getCurrentFlowFromFlow(payload.flow);
            form.setValue('current_flow', cf);
            continue;
          }
          form.setValue(k, v);
        }
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 422) {
          recordErrorEvent({ error });
          router.push('/404');
          return;
        }
      },
    });

  // ========================================================================
  // Create Snapshot

  const { mutate: createSnapshot, isLoading: isCreateSnapshotLoading } =
    useCreateSnapshot<CreateSnapshotPayload, SnapshotData>({
      onSuccess: (_result) => {
        router.replace({
          pathname: router.pathname,
          query: { ...router.query, uuid: _result.uuid },
        });
      },
      onError: (error: AxiosError) => {
        recordErrorEvent({ error });
      },
    });

  // ========================================================================
  // Update Snapshot

  const updateSnapshotPayload = getUpdateSnapshotPayload(
    formData,
    multiStep.currentStep as Step
  );

  const { mutate: updateSnapshot, isLoading: isUpdateSnapshotLoading } =
    useUpdateSnapshot<UpdateSnapshotPayload, SnapshotData>(
      uuid,
      snapshot?.attributes
    );

  const onUpdateSnapshot = (optionalPayload: Partial<FormData> = {}) => {
    const payload = { ...updateSnapshotPayload, ...optionalPayload };
    updateSnapshot(payload, {
      onSuccess: () => {
        recordPublicEvent(getEventPayload(payload));
        multiStep.goNext();
      },
      onError: (error: AxiosError) => {
        recordErrorEvent({ error });
      },
    });
  };

  // ========================================================================
  // Check State Eligibility

  const state = formData.property_state;

  const {
    isLoading: isStateEligibilityCheckLoading,
    refetch: onCheckStateEligibility,
  } = useStateEligibilityCheck(state, partnerSlug, {
    onSuccess: (_result) => {
      const { is_eligible } = _result;

      const payload = {
        ...updateSnapshotPayload,
        is_state_eligible: is_eligible,
        error: is_eligible ? null : 'state_not_available',
      };

      updateSnapshot(payload, {
        onSuccess: () => {
          if (is_eligible) {
            recordPublicEvent(getEventPayload(payload));
            multiStep.goNext();
          }
        },
      });
    },
    onError: (error: AxiosError) => {
      recordErrorEvent({ error });
    },
    enabled: false,
  });

  // ========================================================================
  // Fetch Property UUID

  const fullAddress = formData.full_address;

  const { refetch: onFetchPropertyUUID, isFetching: isFetchingPropertyUUID } =
    usePropertyUUID(fullAddress, {
      onSuccess: (property_uuid) => {
        if (!property_uuid) {
          multiStep.goToStep(STEPS.DEPARTING_RESIDENCE);

          form.setError('full_address', {
            message: 'Please provide a valid address',
          });
          return;
        }

        form.setValue('property_uuid', property_uuid);

        const payload = { ...updateSnapshotPayload, property_uuid };
        updateSnapshot(payload, {
          onSuccess: () => {
            recordPublicEvent(getEventPayload(payload));
            multiStep.goNext();
          },
        });
      },
      onError: (error: AxiosError) => {
        recordErrorEvent({ error });
      },
      isPublic: true,
      enabled: false,
    });

  // ========================================================================
  // Calculate Equity Unlock

  const { refetch: onCalculateEquity, isFetching: isCalculateEquityLoading } =
    useCalculateLenderEquity(getCalculateEquityPayload(formData), {
      onSuccess: (_result) => {
        const payload = {
          ...updateSnapshotPayload,
          equity_unlock_result: _result,
        };

        form.setValue('equity_unlock_result', _result);
        updateSnapshot(payload, {
          onSuccess: () => {
            recordPublicEvent(getEventPayload(payload));
            const issues = getCalculationIssuesLabels(formData);

            if (issues.length === 0) {
              multiStep.goToStep(STEPS.EQUITY_UNLOCK_AMOUNT);
              return;
            }

            openModal(
              <EquityCalculationModal
                issues={issues}
                onClose={closeModal}
                isLoading={isUpdateSnapshotLoading}
                onConfirm={() => {
                  closeModal();
                  multiStep.goToStep(STEPS.EQUITY_UNLOCK_AMOUNT);
                }}
              />
            );
          },
        });
      },
      onError: (error) => {
        recordErrorEvent({ error });
      },
      disableModal: true,
      enabled: false,
    });

  const {
    isLoading: isPropertyEligibilityChecksLoading,
    mutate: checkPropertyEligibility,
  } = useAutomatedBbysEligibilityCheck({
    onSuccess: (_result) => {
      const eligibilityFormData = getEligibilityFormData(_result);
      const isEligible = eligibilityFormData.is_property_eligible;

      const payload = {
        ...updateSnapshotPayload,
        ...eligibilityFormData,
        error: !isEligible ? 'property_not_eligible' : null,
      };

      updateSnapshot(payload, {
        onSuccess: () => {
          if (isEligible) {
            recordPublicEvent(getEventPayload(payload));
            onCalculateEquity();
          }
        },
      });
    },
    onError: (error: AxiosError) => {
      recordErrorEvent({ error });
    },
    disableModal: true,
  });

  const onCheckPropertyEligibility = () => {
    const ecPayload = getPropertyEligibilityCheckPayload(formData);
    checkPropertyEligibility(ecPayload);
  };

  // ========================================================================
  // Get Title Flex

  const { refetch: onFetchTitleFlex, isLoading: isTitleFlexLoading } = useQuery(
    ['TITLE_FLEX', formData.full_address],
    async () => {
      const url = '/api/provider-api-service/titleflex/property/details';
      const params = {
        address: formData.full_address,
      };
      const res = await http.public.get(url, { params });
      return res?.data;
    },
    {
      onSuccess: (_result) => {
        const payload = {
          ...updateSnapshotPayload,
          trust: _result,
        };

        updateSnapshot(payload, {
          onSuccess: () => {
            recordPublicEvent(getEventPayload(payload));
            multiStep.goNext();
          },
        });
      },
      onError: (error: AxiosError) => {
        recordErrorEvent({ error });
        handleErrors(error);
      },
      enabled: false,
    }
  );
  // ========================================================================
  // Submit

  const isDupeLeadError = (error: AxiosError) => {
    return error.response?.data?.errors?.includes?.('duplicated_lead');
  };

  const submitURL = getSubmitUrl(formData.current_flow, formData.finance_type);

  const { mutate: onSubmit, isLoading: isSubmitLoading } = useMutation(
    async () => {
      const payload = getSubmitPayload(formData);

      const res = await http.public.post<SubmissionResponse>(
        submitURL,
        payload
      );
      return res.data?.data?.attributes;
    },
    {
      onSuccess: (_result) => {
        const submit_response = getSubmitResponse({
          result: _result,
          current_flow: formData.current_flow,
          finance_type: formData.finance_type,
        });

        const payload = {
          ...formData,
          submitted_step: STEPS.SUCCESS,
          has_submitted: true,
          submit_response,
        };

        updateSnapshot(payload, {
          onSuccess: () => {
            recordPublicEvent(getEventPayload(payload));
            multiStep.goToStep(STEPS.SUCCESS);
          },
        });
      },
      onError: (error: AxiosError) => {
        if (isDupeLeadError(error)) {
          return;
        }

        const payload = {
          ...formData,
          submitted_step: STEPS.REVIEW_AND_SUBMIT,
        };

        const eventPayload = getEventPayload({ ...payload, error });

        updateSnapshot(payload, {
          onSuccess: () => {
            recordPublicEvent(eventPayload);
          },
        });
        recordErrorEvent(getEventPayload(eventPayload));
        handleErrors(error);
      },
      retry: (failureCount, error: AxiosError) => {
        if (failureCount > 0 && isDupeLeadError(error)) {
          const payload = {
            ...updateSnapshotPayload,
            has_submitted: true,
          };

          updateSnapshot(payload, {
            onSuccess: () => {
              recordPublicEvent(getEventPayload(payload));
              multiStep.goToStep(STEPS.SUCCESS);
            },
          });
          return false;
        }
        return (
          failureCount <= 3 &&
          (!error?.response?.status ||
            [500, 503].includes(error?.response?.status))
        );
      },
      retryDelay: 500,
    }
  );

  // ========================================================================
  // Manage flow error

  const onSetFlowError = (
    error: FlowError,
    optionalPayload: Partial<FormData> = {}
  ) => {
    const payload = { ...updateSnapshotPayload, ...optionalPayload };
    updateSnapshot({ ...payload, error });
  };

  const onClearFlowError = () => {
    updateSnapshot({ ...updateSnapshotPayload, error: null });
  };

  // ========================================================================
  // Create a new snapshot if no uuid in url

  useEffect(() => {
    if (!uuid) {
      createSnapshot({
        partner_slug: partnerSlug,
        flow: flowValue,
        current_flow: currentFlowValue,
        version: 3,
      });
    }
  }, [uuid, partnerSlug, flowValue, createSnapshot, currentFlowValue]);

  // ========================================================================
  // Populate form with local storage data

  useEffect(() => {
    const localFieldsStr = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (localFieldsStr) {
      const localFields = JSON.parse(localFieldsStr);
      form.setValue('remember_me', true);

      for (const key in localFields) {
        form.setValue(key as FormKey, localFields[key]);
      }
    }
  }, [form]);

  // ========================================================================
  // Handle initial page loading state

  useEffect(() => {
    if (!isFetchSnapshotLoading && !isCreateSnapshotLoading) {
      setIsPageLoading(false);
    }
  }, [isFetchSnapshotLoading, isCreateSnapshotLoading]);

  // ========================================================================
  // Navigate to initial step

  useRunOnce(
    () => {
      const { submitted_step, has_submitted, version } =
        snapshot?.attributes?.payload;

      if (has_submitted) {
        multiStep.goToStep(STEPS.SUCCESS);
        return;
      }

      const isV3 = version === 3;
      const initialStep = isV3 ? submitted_step : STEPS.LEAD_INFORMATION;
      multiStep.goToStep(initialStep);
    },
    { enabled: !!snapshot }
  );

  // ========================================================================
  // Render

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isCalculateEquityLoading) {
    return <EquityCalculationLoading />;
  }

  const isNextButtonLoading =
    isUpdateSnapshotLoading ||
    isStateEligibilityCheckLoading ||
    isPropertyEligibilityChecksLoading ||
    isFetchingPropertyUUID ||
    isTitleFlexLoading ||
    isSubmitLoading;

  const theme = {
    colors: {
      primary: brand?.primary_color,
      secondary: brand?.secondary_color,
    },
    logoUrl: brand?.logo_url,
  };

  return (
    <LeadSubmissionContext.Provider
      value={{
        isLoading: isNextButtonLoading,
        onUpdateSnapshot,
        onCheckStateEligibility,
        onFetchPropertyUUID,
        onSetFlowError,
        onClearFlowError,
        onCheckPropertyEligibility,
        onSubmit,
        onFetchTitleFlex,
      }}
    >
      <BrandThemeProvider theme={theme}>
        <Upload.Root {...upload}>
          <Form methods={form}>
            <MultiStep.Root {...multiStep}>
              {formData.error ? (
                <FlowErrorView
                  error={formData.error}
                  onGoBack={() => onClearFlowError()}
                />
              ) : (
                <Layout>
                  <Header />
                  <Sidebar />

                  <MultiStep.Step name={STEPS.LEAD_INFORMATION}>
                    <LeadInformation />
                  </MultiStep.Step>

                  <MultiStep.Step name={STEPS.DEPARTING_RESIDENCE}>
                    <DepartingResidence />
                  </MultiStep.Step>

                  <MultiStep.Step name={STEPS.HOME_VALUE}>
                    <HomeValue />
                  </MultiStep.Step>

                  <MultiStep.Step name={STEPS.LIENS_SELECTION}>
                    <LiensSelection />
                  </MultiStep.Step>

                  <MultiStep.Step name={STEPS.LIENS_BALANCES}>
                    <LiensBalances />
                  </MultiStep.Step>

                  <MultiStep.Step name={STEPS.PROGRAM_ELIGIBILITY}>
                    <ProgramEligibility />
                  </MultiStep.Step>

                  <MultiStep.Step name={STEPS.EQUITY_UNLOCK_AMOUNT}>
                    <EquityCalculation />
                  </MultiStep.Step>

                  {isLeadSubmissionPhase2 ? (
                    <>
                      <MultiStep.Step name={STEPS.TRUST}>
                        <Trust />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.AGE_RESTRICTED_COMMUNITY}>
                        <AgeRestrictedCommunity />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.PRIMARY_RESIDENCE}>
                        <PrimaryResidence />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.CREDIT_SCORE}>
                        <CreditScore />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.CLIENT_FINANCE}>
                        <ClientFinance />
                      </MultiStep.Step>

                      {/* node starts here */}
                      <MultiStep.Step name={STEPS.NEW_HOME_UNDER_CONTRACT}>
                        <NewHomeUnderContract />
                      </MultiStep.Step>

                      {/* branch to here if answered yes from node */}
                      <MultiStep.Step name={STEPS.NEW_HOME_CLOSING_DATE}>
                        <NewHomeClosingDate />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.NEW_HOME_PURCHASE_FUNDS}>
                        <NewHomePurchaseFunds />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.NEW_HOME_PURCHASE_CONTRACT}>
                        <NewHomePurchaseContract />
                      </MultiStep.Step>

                      {/* branche to here if answered no from node */}
                      <MultiStep.Step name={STEPS.NEW_HOME_PURCHASE_TIMELINE}>
                        <NewHomePurchaseTimeline />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.NEW_HOME_TARGET_EQUITY_AMT}>
                        <NewHomeTargetEquityAmount />
                      </MultiStep.Step>

                      {/* advance to here from either branch */}
                      <MultiStep.Step name={STEPS.ADD_CLIENT_INFORMATION}>
                        <AddClientInformation />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.ADD_AGENT_INFO}>
                        <AddAgentInfo />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.ADD_ASSISTANT_INFO}>
                        <AddAssistantInfo />
                      </MultiStep.Step>
                    </>
                  ) : (
                    <>
                      <MultiStep.Step name={STEPS.CLIENT_INFORMATION}>
                        <ClientInformation />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.FINANCIAL_TYPE}>
                        <FinancialType />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.FINANCIAL_INFORMATION}>
                        <FinancialInformation />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.AGENT_INFORMATION}>
                        <AgentInformation />
                      </MultiStep.Step>

                      <MultiStep.Step name={STEPS.LOAN_OFFICER_INFORMATION}>
                        <LoanOfficerInformation />
                      </MultiStep.Step>
                    </>
                  )}

                  <MultiStep.Step name={STEPS.REVIEW_AND_SUBMIT}>
                    <ReviewAndSubmit />
                  </MultiStep.Step>

                  <MultiStep.Step name={STEPS.SUCCESS}>
                    <Success />
                  </MultiStep.Step>
                </Layout>
              )}
            </MultiStep.Root>
          </Form>
        </Upload.Root>
      </BrandThemeProvider>
    </LeadSubmissionContext.Provider>
  );
};
